import { ActionIcon, AppShell, Avatar, Box, Burger, Card, Flex, Group, Menu, Popover, SimpleGrid, Text, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import {
  IconChevronDown,
  IconFileInvoice,
  IconGridDots,
  IconInbox,
  IconLayoutList,
  IconLogout,
  IconMoneybag,
  IconNetwork,
  IconNotebook,
  IconPhoto,
  IconWallet,
} from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { LogoutApi } from "../../apis";
import { ReactComponent as EraLogo } from "../../assets/logo.svg";
import noimage from "../../assets/no-image.png";
import { IAuth } from "../../interfaces/IAuth";
import { message } from "../../utils/message";
import { useScreenDetector } from "../screen-detector";

export const Header = ({ opened, setOpened }: { opened: boolean; setOpened: (e: boolean) => void }) => {
  const { classes, theme } = useStyles();
  const { isMobile } = useScreenDetector();
  const { user, accessToken } = useSelector((state: { auth: IAuth }) => state.auth);

  const logout = async () => {
    try {
      await LogoutApi.logout();
      window.location.href = "https://dev-cp.dehub.mn/login";
    } catch (error: any) {
      message.error(error?.message);
    }
  };

  const mockdata = [
    {
      title: "Network",
      icon: IconNetwork,
      color: "grape",
      link: `https://dev-network.dehub.mn/access?token=${accessToken}`,
      disabled: user?.permissions?.find((c) => c.module === "NETWORK") ? true : false,
    },
    {
      title: "Invoice",
      icon: IconFileInvoice,
      color: "yellow",
      link: `https://dev-invoice.dehub.mn/access?token=${accessToken}`,
      disabled: user?.permissions?.find((c) => c.module === "INVOICE") ? true : false,
    },
    {
      title: "Inventory",
      icon: IconInbox,
      color: "violet",
      link: `https://dev-item.dehub.mn/access?token=${accessToken}`,
      disabled: user?.permissions?.find((c) => c.module === "INVENTORY") ? true : false,
    },
    {
      title: "Payment",
      icon: IconWallet,
      color: "blue",
      link: `https://dev-payment.dehub.mn/access?token=${accessToken}`,
      disabled: user?.permissions?.find((c) => c.module === "PAYMENT") ? true : false,
    },
    {
      title: "Order",
      icon: IconLayoutList,
      color: "cyan",
      link: `https://dev-order.dehub.mn/access?token=${accessToken}`,
      disabled: user?.permissions?.find((c) => c.module === "ORDER") ? true : false,
    },
    {
      title: "Finance",
      icon: IconMoneybag,
      color: "blue",
      link: `https://dev-cp.dehub.mn?verifyConfirmType=modal`,
      disabled: user?.permissions?.find((c) => c.module === "FINANCE") ? true : false,
    },
  ];

  const items = mockdata.map((item) => {
    return (
      <UnstyledButton
        disabled={!item.disabled}
        key={item.title}
        className={classes.item}
        onClick={() => {
          window.location.href = item.link;
        }}
        bg={item.disabled ? item.color + ".0" : "gray.0"}>
        <item.icon color={item.disabled ? theme.colors[item.color][6] : "gray"} size="2rem" />
        <Text size="xs" mt={7}>
          {item.title}
        </Text>
      </UnstyledButton>
    );
  });

  return (
    <AppShell.Header>
      <Group className={classes.header}>
        {!isMobile ? (
          <Flex align="center" gap={8}>
            <a href="https://dev-cp.dehub.mn/" className={classes.logoBox}>
              <EraLogo className={classes.logo} />
              <span>
                <div className={classes.logoText}>DeHUB</div>
                <div className={classes.logoDesc}>платформ</div>
              </span>
            </a>
            <Box h="32px" style={{ borderRight: "1px solid #868E96" }}></Box>
            <Popover width={400} position="bottom" withArrow shadow="md">
              <Popover.Target>
                <ActionIcon size="lg" variant="transparent">
                  <IconGridDots size="2.0rem" stroke={1} />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <Card p="sm">
                  <SimpleGrid cols={3}>{items}</SimpleGrid>
                </Card>
              </Popover.Dropdown>
            </Popover>
            <Text className={classes.moduleText}>ЗАХИАЛГА МОДУЛЬ</Text>
          </Flex>
        ) : (
          <div>
            <Flex p="xs">
              <Burger color="gray" size="sm" opened={opened} onClick={() => {}} />
            </Flex>
          </div>
        )}
        <Menu width={260} position="bottom-end" transitionProps={{ duration: 150, exitDuration: 150, transition: "pop-top-right" }}>
          <Menu.Target>
            <UnstyledButton className={classes.user}>
              <Flex gap="xs" align="center">
                <Avatar src={user?.avatar !== null ? user?.avatar : noimage}>
                  <IconPhoto />
                </Avatar>
                <div style={{ flex: 1 }}>
                  <Group gap={5}>
                    <Text size="sm" fw={500} c="#141517">
                      {user?.lastName ? user?.lastName[0] : ""}.{user?.firstName !== null ? user?.firstName : "-"}
                    </Text>
                    <Text size="xs" fw={500} c="#44566C">
                      {"("}
                      {user?.currentBusiness?.type ? (user?.currentBusiness?.type === "SUPPLIER" ? "Нийлүүлэгч" : "Худалдан авагч") : "-"}
                      {")"}
                    </Text>
                  </Group>
                  <Text size="sm" fw={500} c="#44566C">
                    {user?.email ?? "-"}
                  </Text>
                </div>
                <IconChevronDown size={14} stroke={1.5} />
              </Flex>
            </UnstyledButton>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              onClick={() => (window.location.href = "https://dev-cp.dehub.mn/auth/profile/dan")}
              leftSection={<IconNotebook size={20} color={theme.colors.indigo[6]} stroke={1.5} />}>
              Миний мэдээлэл
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={() => logout()} color="red" leftSection={<IconLogout size={20} stroke={1.5} />}>
              Гарах
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </AppShell.Header>
  );
};

const useStyles = createStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    justifyItems: "center",
    backgroundColor: theme.colors.violet[0],
    height: "100%",
    borderBottom: `4px solid #15aabf`,
  },

  user: {
    padding: theme.spacing.xs,
    height: "100%",
    "&:hover": {
      backgroundColor: theme.colors.gray[2],
    },
  },

  logoBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0 20px",
  },

  logo: {
    height: 32,
  },

  logoText: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.colors.cyan[6],
    lineHeight: "18px",
  },

  logoDesc: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.colors.gray[6],
    textAlign: "end",
  },

  moduleText: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.colors.cyan[6],

    textTransform: "uppercase",
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "8px",
    height: "90px",
    transition: "box-shadow 150ms ease, transform 100ms ease",
    ":hover": {
      boxShadow: theme.shadows.sm,
    },
  },

  card: {
    border: 0,
  },

  title: {
    fontWeight: 700,
  },
}));
